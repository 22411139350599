/*-----------------------------------*/
/*--- IMPORT STYLES FOR FASTPAGES ---*/
@import "minima/fastpages-styles";

/*-----------------------------------*/
/*----- ADD YOUR STYLES BELOW -------*/

// If you want to turn off dark background for syntax highlighting, comment or delete the below line.
@import "minima/fastpages-dracula-highlight";

// styles for the about page
.ref-job-title {
  margin-top: .25em !important;
  margin-bottom: .25em !important;
  color: rgb(15, 15, 15);
  font-size: 18px !important;
}

.ref-description {
  color: rgb(88, 88, 88);
  font-size: 15px !important;
  margin-top: .05rem !important;
  margin-bottom: .05rem !important;
}

@media screen and (min-width: 544px) {
  .mobile-break {
    display: none;
  }
}

//// Add logo before the main title
//.site-title::before {
//	content: url("https://via.placeholder.com/30.jpg");
//	display: inline-block;
//	position: relative;
//	width: px;
//	//top: 5px;
//}

// Styles for Goodreads embed
.gr_grid_container {
  /* customize grid container div here. eg: width: 500px; */
}

.gr_grid_book_container {
  /* customize book cover container div here */
  float: left;
  width: 39px;
  height: 60px;
  padding: 0 0;
  overflow: hidden;
}
